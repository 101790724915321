table.MuiTable-root.table-shortcuts tbody td {
  font-size: 15px;
  color: inherit;
  border: none !important;
}
table.MuiTable-root.table-shortcuts thead th {
  border: none !important;
  font-size: 16px;
  font-weight: 500;
}
table.MuiTable-root.table-shortcuts .description-cell {
  padding: 7px 16px;
}
table.MuiTable-root.table-shortcuts .key-cell-head {
  padding-left: 0;
}
.MuiTypography-root.MuiTypography-body2.toggle-switch {
  cursor: pointer;
  text-decoration: underline;
  margin-right: 10px;
}
.paper-shortcuts {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37%;
  max-width: 800px;
  max-height: 90vh;
  overflow: auto;
  background-color: var(--background-paper);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  padding: 0;
  border-radius: 8px;
}
table.MuiTable-root.table-shortcuts .key-cell {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  color: #333;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0.1em;
  min-width: 2em;
  padding: 0.1em 0.6em;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: bottom;
}
table.MuiTable-root.table-shortcuts .key-separator {
  padding: 5px;
  font-size: 14px;
}
.thead-shortcuts {
  margin: 0;
  padding: 8px 8px 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shortcut-key-que {
  display: inline-block;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  width: 6px;
  height: 6px;
  line-height: 6px;
  font-size: 12px;
  font-weight: bold;
  background: #00000021;
}
/* PSA speciifc css starts*/
body.worldline.psa .thead-shortcuts {
  background-color: #41b4d2;
}
body.worldline.psa .MuiTypography-root.MuiTypography-body2.toggle-switch {
  color: var(--psa-user-name-link-color);
}

/* PSA speciifc css ends*/

/* common css starts */
body.worldline .thead-shortcuts {
  background-color: var(--default_table_header-bg);
  color: #fff;
}
body.worldline .MuiTypography-root.MuiTypography-body2.toggle-switch {
  color: var(--default-user-name-link-color);
}
/* common css ends */
