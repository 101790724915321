.changePassModal {
  width: 650px;
  background-color: #fff;
  margin: 10% auto;
  padding: 0 0px 20px 0;
  border-radius: 5px;
}
.changePassModal .change-password-button {
  border-radius: 20px;
  margin-bottom: 5px;
  color: #fff;
}

.changePassModal .changePassModalHeaderWrapper {
  
  color: #fff;
  padding: 10px;
  width: 100%;
  margin: 0;
  justify-content: space-between;
}
.loader-password {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 50%;
}
.changePassModal .changePassModalHeaderWrapper .closeIcon {
  padding-right: 20px;
}
.changePassModal input {
  padding: 7px 14px;
}
.changePassModal .changePasswordModalInputLabel {
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.24px;
  color: #414141;
  text-transform: uppercase;
  opacity: 1;
  margin: 15px 0 5px 0;
  font-size: 12px;
}
.changePassModalGuideLineHeader {
  padding: 2px 0;
  font-family: "Nunito", sans-serif;
  color: #414141;
}
.guidelines-wrapper li {
  padding: 5px 0;
  font-family: "Nunito", sans-serif;
  color: #414141;
  font-size: 14px;
}
/*PSA theme starts*/
.psa-theme.wl-parent.changePassModal .changePassModalHeaderWrapper {
  background-color: #41b4d2;
}
.psa-theme.wl-parent.changePassModal .cancel-btn {
  color: #d32f2f;
  background-color: transparent;
  border: 1px solid rgba(211, 47, 47, 0.5);

}
.psa-theme.wl-parent.changePassModal .cancel-btn:hover{
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(211, 47, 47, 0.04);
    border: 1px solid #d32f2f;
}
.psa-theme.wl-parent.changePassModal .change-password-button {
  background: #0099ff;
  color: #fff;
}

.psa-theme.wl-parent.changePassModal .change-password-button:hover {
  background: #108ee9;
  color: #fff;
}
/*PSA theme ends*/

/*default theme starts*/
.wl-parent.changePassModal .changePassModalHeaderWrapper {
  background-color: var(--default_table_header-bg);
}
.wl-parent.changePassModal .cancel-btn {
  color:rgb(0,0,0);
  background-color: rgb(245,195,200);
  border:none
}
.wl-parent.changePassModal .change-password-button {
  background: #46beaa;
  color: rgb(0,0,0);
}

.wl-parent.changePassModal .change-password-button:hover {
  background: #46beaa;
  color: rgb(0,0,0);
}
/*default theme end*/
