.status-active {
  font-size: 13px;
  color: #0e9462;
  background-color: #d2fcc9;
  padding: 2px 3px;
  border-radius: 6px;
}
.status-inactive {
  font-size: 13px;
  color: #99999e;
  background-color: #dfdfdf;
  padding: 2px 3px;
  border-radius: 6px;
}
.status-active {
  font-size: 13px;
  color: #0e9462;
  background-color: #d2fcc9;
  padding: 2px 3px;
  border-radius: 6px;
}
.status-inactive {
  font-size: 13px;
  color: #99999e;
  background-color: #dfdfdf;
  padding: 2px 3px;
  border-radius: 6px;
}
.MuiSwitch-sizeMedium.MuiSwitch-root {
  height: 24px;
  padding: 8px;
}
.MuiButtonBase-root.MuiSwitch-switchBase[aria-label="Toggle visibility"] {
  padding: 5px;
}
.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.emailFilterInput div {
  max-width: none;
}
.MuiList-root.MuiList-padding.MuiMenu-list
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters[role="menuitem"] {
  text-transform: none;
  padding: 0 5px 0 15px;
  font-size: 15px;
}

.MuiList-root.MuiList-padding.MuiMenu-list
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters[data-value="Select"] {
  color: transparent;
}

.MuiList-root.MuiList-padding.MuiMenu-list
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters[data-value="Active"],
.MuiList-root.MuiList-padding.MuiMenu-list
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters[data-value="Inactive"],
.MuiList-root.MuiList-padding.MuiMenu-list
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters[data-value="All"] {
  text-transform: none;
  padding: 18px 10px;
  font-family: Nunito;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.MuiInputBase-root {
  background-color: none !important;
}
.MuiTable-root .bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem !important;
}

.MuiTable-root .bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px !important;
  color: #555 !important;
}

.MuiTable-root .bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome !important;
  color: #555 !important;
}

.MuiTable-root .bootstrap-demo .toggle.collapsed::after {
  content: "+" !important;
  font-size: 20px;
  font-weight: bold;
}

.MuiTable-root .bootstrap-demo .toggle.expanded::after {
  content: "-" !important;
  font-size: 20px;
  font-weight: bold;
}

.MuiTable-root .bootstrap-demo .root {
  padding: 0px !important;
  margin: 0px !important;
}
.tag {
  background-color: #f4f4f4;
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block;
}
.tag:focus-within {
  background-color: #e9e9e9;
  border-color: #a0a0a0;
}
.tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
.tag-remove.disabled,
.tag-remove.readOnly {
  cursor: not-allowed;
}
.tag-remove:focus {
  color: #3c3c3c;
}
.node > label {
  cursor: pointer;
  margin-left: 2px;
}
.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
}
.list-users-wrapper .tag-list {
  margin: 4px;
}
.CreateNode .tag-item {
  display: inline-block;
  margin: 4px;
}
.list-users-wrapper p.MuiFormHelperText-root {
  display: none;
}
.tag-list .tag-item .tag {
  display: none;
}
.status-input-field input.MuiSelect-nativeInput {
  opacity: 1;
  width: 100px;
  border: 0;
  padding: 10px;
  font-size: 14px;
}

.CreateNode .tag-list .tag-item .tag {
  display: block;
  line-height: 18px;
}
.tag-list input.search::placeholder,
.status-input-field input.MuiSelect-nativeInput::placeholder {
  color: rgb(46 51 88 / 45%);
  font-size: 16px;
  font-family: "Nunito", sans-serif;
}
.list-users-wrapper .tag-list input::placeholder {
  font-size: 16px;
  font-family: "Nunito", sans-serif;
}
.list-users-wrapper .tag-list input {
  max-width: 140px;
}

.tag-item:last-child {
  margin-right: 4px;
}
.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px;
}
.node.leaf.collapsed {
  display: none;
}
.node.disabled > * {
  color: gray;
  cursor: not-allowed;
}
.node.match-in-children.hide .node-label {
  opacity: 0.5;
}
.list-users-wrapper .node-label {
  color: #414141 !important;
  font-size: 15px !important;
}
.node.focused {
  background-color: #f4f4f4;
}
.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
}
.toggle:after {
  content: " ";
}
.toggle.collapsed:after {
  content: "+";
}
.tag-item .search {
  padding-left: 5px;
  border: none;
  outline: none;
  font-size: 16px;
  max-width: 170px;
}
#create-user-top-wrapper .tag-item .search::placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
}
.react-dropdown-tree-select .dropdown-content i[role="button"] {
  padding: 0 2px;
  font-size: 14px;
  color: #fff;
  font-style: normal;
  font-weight: bolder;
  border-radius: 3px;
}
.infinite-scroll-component {
  min-height: 100px;
  max-height: 170px;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 0.4vw;
  height: 3px !important;
}
.infinite-scroll-component::-webkit-scrollbar-thumb {
  width: 0.3vw;
  height: 0.01em !important;
  background-color: #dce3f3;
}
.CreateNode .infinite-scroll-component {
  max-height: 155px;
}
.toggle.expanded:after {
  content: "-";
  font-size: 16px;
  padding: 0 2px;
}
.searchModeOn .toggle {
  display: none;
}
.checkbox-item,
.radio-item {
  vertical-align: middle;
  margin: 0 4px 0 0;
  height: 15px;
  width: 15px;
}
.checkbox-item:before {
  content: "\00a0";
  display: inline-block;
  font: 16px;
  height: 11px;
  width: 11px;
  border-radius: 2px;
}
.checkbox-item.simple-select,
.radio-item.simple-select {
  display: none;
}

.hide:not(.match-in-children) {
  display: none;
}
.CreateNode.react-dropdown-tree-select {
  margin-top: 0px;
}
.react-dropdown-tree-select {
  margin-top: 8px;
}
.react-dropdown-tree-select .tag-item .tag {
  padding: 0;
  border: none;
  background-color: transparent;
}
.react-dropdown-tree-select .tag-item button.tag-remove {
  display: none;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 4px;
  line-height: 10px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  border: 1px solid #b9b9b9;
}
.list-users-wrapper .react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 0 4px;
}
.list-users-wrapper .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  border: 1px solid #a5a4a4b5;
  max-width: 230px;
  min-width: 200px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: pointer;
  min-width: 185px;
  max-width: 250px;
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b4b9c5d1;
  border-radius: 5px;
  opacity: 1;
}
.react-dropdown-tree-select .dropdown-content input[type="radio"] {
  appearance: none;
  border: 1px solid #d3d3d3;
  width: 15px;
  height: 15px;
  content: none;
  outline: none;
  margin: 0 9px 0 0;
  display: none;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  content: "\25BE";
  vertical-align: middle;
  color: #2e3358a3;
  margin-right: 2px;
  font-size: 20px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  content: "\25B4";
  vertical-align: middle;
  color: #2e3358a3;
  margin-right: 2px;
  font-size: 20px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
  cursor: not-allowed;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  overflow: hidden;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after {
  color: #b9b9b9;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow .tag-list {
  display: flex;
}
#create-user-top-wrapper .react-dropdown-tree-select input[aria-activedescendant]:placeholder-shown,
#create-user-top-wrapper .react-dropdown-tree-select input[placeholder]:not([placeholder="Select"]),
#edit-uder-top-wrapper .react-dropdown-tree-select input[aria-activedescendant]:placeholder-shown,
#edit-uder-top-wrapper .react-dropdown-tree-select input[placeholder]:not([placeholder="Select"]),
.react-dropdown-tree-select input[placeholder="Choose..."] {
  display: none;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 1;
  background: #fff;
  min-width: 195px;
  width: 195px;
  max-width: fit-content;
  border: 1px solid #0099ff;
  border-radius: 4px;
  resize: horizontal;
  overflow: hidden;
  font-family: "Nunito", sans-serif;
}

.user-list-table .date-range-input-field-header input {
  padding: 8px 0 8px 14px;
  text-overflow: inherit;
}
.user-list-table .date-range-input-field-header button {
  padding: 0 3px 0 0;
}
.user-list-table .dropdown-content .node .node-label {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  color: #686666;
  font-weight: 400;
}
body.worldline .checkbox-item:disabled:before {
  display: none;
}
.list-users-wrapper .react-dropdown-tree-select .dropdown .dropdown-content {
  resize: horizontal;
  overflow: hidden;
  min-width: 200px !important;
  width: 200px;
  max-width: fit-content !important;
  min-height: 100px !important;
}
.react-dropdown-tree-select .dropdown .dropdown-content ul {
  margin: 0;
  padding: 0;
}
.status-input-field .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined,
.entityFilterInput .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined,
.roleFilterInput .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined {
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1450px) {
  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
    min-width: 150px;
  }
}
@media only screen and (max-width: 719px) {
  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
    width: fit-content;
    min-width: 100px;
    max-width: 150px;
    white-space: break-spaces;
    word-break: break-all;
    line-height: 14px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 720px) and (max-width: 850px) {
  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
    width: fit-content;
    min-width: 150px;
    max-width: 200px;
    font-size: 15px;
  }
}
table.MuiTable-root .user-name-link {
  text-decoration: none;
  cursor: pointer;
}
/*psa theme starts*/
body.psa.worldline .user-name-link {
  color: var(--psa-user-name-link-color);
}
body.psa.worldline .react-dropdown-tree-select .dropdown-content i[role="button"] {
  background-color: #0099ff;
}
body.psa.worldline .checkbox-item:before {
  border: 2px solid #0099ff !important;
}
/*psa theme ends*/
/*default theme starts*/
body.worldline .user-name-link {
  color: var(--default-user-name-link-color);
}
body.worldline .checkbox-item:before {
  border: 2px solid #46beaa !important;
}
body.worldline .react-dropdown-tree-select .dropdown-content i[role="button"] {
  background-color: #46beaa;
}
/*default teme ends*/
