img[alt="CredentialManagementBanner"] {
  width: "500px";
  height: auto;
}
.cred-mgmt .MuiBox-root .bank-logo {
  height: auto;
  width: 120px;
}
.cred-mgmt .cred-mgmt-input-field input::placeholder {
  font-size: 13px;
}
.back-to-login {
  text-decoration: underline;
  font-weight: bold;
  opacity: 0.7;
}
.cred-mgmt .submit-btn {
  border-radius: 20px;
  width: 50%;
  color: #fff;
}
.cred-mgmt .input-label {
  margin-bottom: 7px;
  font-weight: bold;
  opacity: 1;
  font-size: 12px;
}
.cred-mgmt .header-5 {
  margin: 10px 0;
  font-size: 22px;
  font-weight: 500;
  opacity: 1;
}
.cred-mgmt h5,
.cred-mgmt label {
  opacity: 0.8;
}
.set-password-page input {
  padding: 10px;
}
.set-password-page .MuiFormControl-root.MuiTextField-root {
  width: 290px;
}
.set-password-page .guideline-header {
  color: #414141;
  font-weight: bold;
  font-size: 18px;
  margin: 25px 0 5px 0;
  opacity: 0.7;
}
.set-password-page label.confirm-password {
  margin-top: 20px;
}
.set-password-page .submit-btn.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgb(255, 255, 255);
  pointer-events: none;
  cursor: default;
  box-shadow: none;
}

/*PSA theme starts*/
body.psa.worldline .cred-mgmt .submit-btn:hover {
  background: #108ee9;
}
body.psa.worldline .cred-mgmt .submit-btn {
  background: #0099ff;
}
.cred-mgmt {
  background-color: #eff1f5;
  color: rgb(65 65 65 / 83%);
}
.set-password-page .WL-Footer {
  position: relative;
  top: 0;
  background-color: inherit;
  margin-top: -2%;
}

.set-password-page .MuiListItem-root.MuiListItem-gutters.MuiListItem-padding {
  color: rgb(65 65 65 / 83%);
  font-family: "Nunito";
  font-weight: 700;
  opacity: 1;
}
body.psa.worldline .set-password-page.cred-mgmt .right-wrapper.MuiGrid-item {
  max-width: none;
}
body.worldline.psa .set-password-page .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-5.right-wrapper {
  margin-top: 63px;
}
body.worldline.psa .set-password-page .WL-Footer {
  margin-top: -2%;
  padding-top: 0;
}
/*PSA theme ends*/
/*default theme starts*/
body.worldline .cred-mgmt .submit-btn:hover {
  background: #46beaa;
}
body.worldline .set-password-page .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-5.right-wrapper {
  margin-top: 8%;
}
body.worldline .set-password-page .WL-Footer {
  padding-top: 20px;
  margin-top: 0;
}
body.worldline .set-password-page.cred-mgmt .right-wrapper.MuiGrid-item {
  max-width: 430px;
}
body.worldline .cred-mgmt .submit-btn {
  background: #46beaa;
}
body.worldline .cred-mgmt .logo-dynamic {
  height: 70px;
  margin-left: 0;
}
/*default theme ends*/
@media screen and (max-height: 800px) {
  .set-password-page.cred-mgmt {
    padding-bottom: 4%;
  }
}
