div[data-testid="profile-page"] .MuiTypography-caption.label_header {
  font-weight: bold;
  font-size: 14px;
  color: rgb(65, 65, 65);
  opacity: 0.9;
  margin: 7px 0;
}
.profileContainer {
  width: 95%;
}
.MuiFormControl-root.MuiTextField-root.inputProfile
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-formControl
  .MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.inputboxProfile
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4.MuiGrid-grid-lg-3
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl
  .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8.5px 12px;
}

.profileContainer .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1 {
  margin: 10px 5px 10px 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.userProfileCard {
  margin: 2% 4% 0%;
}

div[data-testid="theme-name"]
  button.dashed-icon.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium {
  top: 0;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.UserProfileCustomToolBar {
  margin: 0% 4%;
  min-height: 64px;
}

.MuiTypography-root.MuiTypography-body1.customStatusClassActive {
  width: fit-content;
  text-align: center;
  font-size: 13px;
  color: rgb(14, 148, 98);
  background-color: rgb(210, 252, 201);
  padding: 2px 3px;
  border-radius: 6px;
}

.SuccessErrorMessageGrid {
  width: 80%;
  top: 8%;
  z-index: 2500;
}

.SuccessErrorMessageGrid .success-msg {
  flex-grow: initial;
  height: 30px;
  font-family: Nunito;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  background-color: rgb(46, 125, 50);
  color: rgb(255, 255, 255);
  width: fit-content;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 16px;
  align-items: center;
}

.profileContainer .MuiGrid-root.MuiGrid-grid-md-3 {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.SuccessErrorMessageGrid .global-error-msg {
  flex-grow: initial;
  height: fit-content;
  font-family: Nunito;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  background: #ff0000b3;
  color: white;
  width: fit-content;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  align-items: center;
}
.SuccessErrorMessageGrid .global-error-msg .error-message-items,
.SuccessErrorMessageGrid .global-error-msg .error-message-header {
  text-align: left;
}
.MuiTypography-root.MuiTypography-body1.customStatusClassInactive {
  font-size: 13px;
  color: #99999e;
  background-color: #dfdfdf;
  padding: 2px 3px 3px 8px;
  border-radius: 6px;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4.MuiGrid-grid-md-4.MuiGrid-grid-lg-2.StatusFiled {
  max-width: 5.666667%;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.userProfileCard {
  margin: 0% 4%;
}

.userProfileCard .container-paper .MuiAccordionDetails-root .MuiBox-root .MuiBox-root .MuiBox-root,
.userProfileCard
  .container-paper
  .entity-assigned-panel
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2
  div:nth-of-type(3)
  > div {
  display: flex;
  flex-wrap: nowrap;
}
.userProfileCard .Mui-TableHeadCell-ResizeHandle-Wrapper.MuiBox-root {
  position: relative;
  margin-right: 15px;
  padding-left: 0;
  padding-right: 0;
}
/* .userProfileCard .Mui-TableHeadCell-Content-Labels.MuiBox-root {
  padding-left: 5px;
} */
.userProfileCard .container-paper .MuiAccordionDetails-root .MuiBox-root .MuiBox-root .MuiBox-root .MuiBox-root,
.userProfileCard
  .entity-assigned-panel
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2
  div:nth-of-type(3)
  > div
  > div {
  width: auto;
}

.userProfileCard
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.userProfileCard
  .success-message {
  position: absolute;
  top: 116px;
  z-index: 3000;
  text-align: center;
  background: green;
  color: white;
  margin-left: 30%;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 15px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.userProfileCard .error-message {
  position: absolute;
  top: 116px;
  z-index: 3000;
  text-align: center;
  background: #ff0000b3;
  color: white;
  margin-left: 30%;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 15px;
}
.MuiButtonBase-root.MuiButton-root.MuiLoadingButton-root.MuiLoadingButton-loading.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.Mui-disabled.MuiButton-root.MuiLoadingButton-root.MuiLoadingButton-loading.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.loaderButton {
  padding: 5px 32px;
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}

.loaderButton .MuiButton-startIcon.MuiLoadingButton-startIconLoadingStart.MuiButton-iconSizeMedium {
  margin-right: 0px;
  margin-left: -10px;
}

.profile-save {
  padding: 6px 40px !important;
}
.userProfileCard table.MuiTable-root tbody td {
  padding-left: 14px;
}
.userProfileCard table thead tr th:nth-of-type(1) div.Mui-TableHeadCell-Content {
  padding-right: 0;
  display: flex;
  justify-content: center;
}
.userProfileCard table.MuiTable-root thead tr th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft {
  padding: 0 10px 0px 0;
}
.userProfileCard table thead.MuiTableHead-root th div.Mui-TableHeadCell-Content {
  padding: 5px 10px;
  margin-bottom: 0;
  height: 100%;
}
.userProfileCard .description-cell-span {
  width: 40vw;
}
.userProfileCard table {
  display: block;
}
.userProfileCard tbody {
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
}
.userProfileCard table.MuiTable-root tbody td:nth-of-type(1) {
  padding: 7.5px 0;
  justify-content: center;
  display: flex;
}
.userProfileCard tbody::-webkit-scrollbar-thumb {
  background-color: #dce3f3;
  border-radius: 10px;
  height: 10px;
}
.userProfileCard tbody::-webkit-scrollbar {
  width: 8px;
}

.userProfileCard table thead tr th:nth-of-type(1) .Mui-TableHeadCell-ResizeHandle-Wrapper,
.userProfileCard table thead tr th:nth-of-type(3) .Mui-TableHeadCell-ResizeHandle-Wrapper {
  display: none;
}

.userProfileCard div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 div:nth-of-type(3) {
  box-shadow: none;
}
.userProfileCard .MuiAccordionSummary-contentGutters h3 {
  margin: 0;
}
.userProfileCard .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.userProfileCard table.MuiTable-root tbody td {
  white-space: pre-wrap;
  min-width: min-content;
}
.userProfileCard table.MuiTable-root tbody td:nth-of-type(3) {
  max-width: 60%;
  overflow: clip;
}
.userProfileCard table.MuiTable-root {
  overflow: hidden;
}
.userProfileCard .table thead tr th:nth-of-type(1) div:nth-of-type(1) {
  padding-left: 10px;
}
.userProfileCard .MuiAccordionSummary-expandIconWrapper {
  margin-right: 5px;
}
/*PSA theme starts*/
body.psa.worldline .userProfileCard .cancel-btn {
  color: #d32f2f;
  background-color: transparent;
  border: 1px solid rgba(211, 47, 47, 0.5);
}
body.psa.worldline .userProfileCard .cancel-btn:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(211, 47, 47, 0.04);
  border: 1px solid #d32f2f;
}
body.psa.worldline .change-password-label {
  color: #0099ff;
  text-decoration: none;
  font-size: 14px;
}
body.psa.worldline .change-password-arrow {
  vertical-align: middle;
  color: #0099ff;
  font-size: large;
}

/*PSA theme ends*/

/*Default theme starts*/

body.worldline .userProfileCard .cancel-btn {
  color: rgb(0, 0, 0);
  background-color: rgb(245, 195, 200);
  border: none;
}
body.worldline .change-password-arrow {
  vertical-align: middle;
  color: #46beaa;
  font-size: large;
}
body.worldline .change-password-label {
  color: #46beaa;
  text-decoration: none;
  font-size: 14px;
}
/*PSA theme ends*/
