.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.menu_list_item div {
  padding: 6px 10px;
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.report_list_item div {
  padding: 6px 7px;
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.report_list_item {
  display: inline-flex;
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.menu_list_item,
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.report_list_item {
  padding-top: 0px;
  padding-bottom: 0;
  padding-left: 13px;
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.report_list_item svg {
  margin-left: 20px;
}

.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.menu_list_item,
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.report_list_item span,
.MuiCollapse-wrapperInner.MuiCollapse-vertical
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button
  .MuiListItemText-root {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  font-family: "Nunito";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.report_list_item span {
  font-size: 1rem;
}
.MuiCollapse-wrapperInner.MuiCollapse-vertical
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button {
  padding: 0px;
  margin-left: 20px;
  margin-right: 10px;
}
.MuiCollapse-wrapperInner.MuiCollapse-vertical
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button
  .MuiListItemText-root {
  margin-left: 52px;
}
.keyboard-shortcuts-wrapper {
  position: absolute;
  bottom: 5%;
}
.keyboard-shortcuts-wrapper svg {
  padding-left: 16px;
  opacity: 0.7;
}
.keyboard-shortcuts-wrapper a.keyboard-shortcuts-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.keyboard-shortcuts-wrapper a.keyboard-shortcuts-link span {
  font-family: Nunito;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
}
/*PSA theme starts*/
body.psa.worldline .selected span,
body.worldline.psa .selected {
  color: #1976d2;
  font-weight: 600;
}
body.psa.worldline .back-arrow-icon {
  display: inline-block;
  color: #0099ff;
}
body.psa.worldline .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight.reports-popover {
  box-shadow: 0 4px 8px 0 rgba(210, 237, 255, 0.3), 0 6px 20px 0 rgba(210, 237, 255, 0.3);
  border: 1px solid rgba(210, 237, 255, 0.7);
}
/*PSA theme ends*/
/*default theme starts*/
body.worldline .selected span,
body.worldline .selected {
  color: #46beaa;
  font-weight: 600;
}
body.worldline .back-arrow-icon {
  display: inline-block;
  color: #46beaa;
}
body.worldline .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight.reports-popover {
  box-shadow: 0 4px 4px 0 rgba(70, 190, 170, 0.2), 0 6px 20px 0 rgba(70, 190, 170, 0.2);
  border: 1px solid rgba(70, 190, 170, 0.6);
}

/*PSA theme ends*/
/*newly css*/
.MuiListItem-root.MuiListItem-gutters .side-menu-icon {
  margin: 10px 5px;
  cursor: pointer;
  width: 21px;
}
.MuiListItem-root.MuiListItem-gutters .side-menu-icon path {
  cursor: pointer;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight.reports-popover div[role="button"] {
  padding: 3px 10px;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight.reports-popover div[role="button"]:first-child {
  color: #000000;
  font-weight: bold;
  cursor: no-drop;
  pointer-events: none;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight.reports-popover {
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
}
