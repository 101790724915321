.twoFAContainer {
  font-size: 16px;
  padding: 20px 20px 0 20px;
  color: #414141;
}
.twoFAHeader {
  display: flex;
  justify-content: space-around;
  background: pink;
  padding: 14px;
  align-items: flex-end;
  background-color: #41b4d2;
}
.twoFA_btn_container {
  justify-content: flex-end;
  padding: 10px 20px 20px;
  display: flex;
}

.MFAModel {
  z-index: 2700 !important;
}
/*PSA theme*/
#psa-theme.wl-theme .twoFAHeader {
  background-color: #41b4d2;
}
#psa-theme.wl-theme.MFAModel .two_fa_btn_color_proceed {
  background-color: #1976d2;
  color:rgb(255, 255, 255)
}
#psa-theme.wl-theme.MFAModel .twoFA_cancel_btn {
  background-color:transparent;
  border: 1px solid rgba(211, 47, 47, 0.5);
  color: #d32f2f;
} 

#psa-theme.wl-theme.MFAModel .twoFA_cancel_btn:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(211, 47, 47, 0.04);
  border: 1px solid #d32f2f;
} 

#psa-theme.wl-theme.MFAModel .twoFA_success_btn {
  border: 1px solid rgba(46, 125, 50, 0.5);
  color: #2e7d32;
  background-color: transparent;
}

#psa-theme.wl-theme.MFAModel .twoFA_success_btn:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(46, 125, 50, 0.04);
  border: 1px solid #2e7d32;
}
/*wl theme*/
.wl-theme .twoFAHeader {
  background-color: var(--default_table_header-bg);
}
.wl-theme.MFAModel .two_fa_btn_color_proceed {
  background-color: #46beaa;
  color:rgb(0,0,0)
}

.wl-theme.MFAModel .twoFA_cancel_btn  {
  color: rgb(0,0,0);
  background-color: rgb(245,195,200); 
  border:none
}

.wl-theme.MFAModel .twoFA_success_btn {
  background-color: #46beaa;
  color:rgb(0,0,0);
  border:none
}
