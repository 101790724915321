img[alt="CredentialManagementBanner"] {
  height: auto;
}
.cred-mgmt .MuiBox-root .bank-logo {
  height: auto;
  width: 120px;
}
.cred-mgmt .cred-mgmt-input-field input::placeholder {
  font-size: 13px;
}
.back-to-login {
  text-decoration: underline;
  font-weight: bold;
  opacity: 0.7;
}
.cred-mgmt .submit-btn {
  border-radius: 20px;
  width: 50%;
}
.cred-mgmt .input-label {
  margin-bottom: 7px;
  font-weight: bold;
  opacity: 0.8;
}
body.worldline .fpPage.cred-mgmt .submit-btn.Mui-disabled {
  background-color: #8080804a;
  color: rgba(0, 0, 0, 0.26) !important;
}
.cred-mgmt .header-5 {
  margin: 10px 0;
  font-size: 22px;
  font-weight: bold;
  opacity: 0.8;
}
.cred-mgmt h5,
.cred-mgmt label {
  opacity: 0.8;
}
/*PSA theme starts*/
body.psa.worldline .cred-mgmt .submit-btn:hover {
  background: #108ee9;
  color: #fff;
}
body.psa.worldline .cred-mgmt .submit-btn {
  background: #0099ff;
  color: #fff;
}
.cred-mgmt {
  background-color: #eff1f5;
  color: #414141;
}
body.worldline .cred-mgmt .right-wrapper.MuiGrid-item#psa {
  border: none;
  background-color: transparent;
  padding: 0;
  max-width: none;
  box-shadow: none;
  height: auto;
  margin-top: 0;
}
body.psa.worldline .cred-mgmt .left-box-wrapper {
  margin-top: 0;
  height: 100%;
}
/*PSA theme ends*/
/*default theme starts*/
.cred-mgmt .right-wrapper.MuiGrid-item {
  border: 1px solid #46beaa;
  background-color: #fafcfe;
  padding: 20px 40px 30px 40px;
  max-width: 400px;
  box-shadow: 1px 3px 3px #aaaaaa;
  margin-top: 8%;
  height: fit-content;
}
body.worldline .cred-mgmt .submit-btn:hover {
  background: #46beaa;
  color: rgb(0, 0, 0);
}
body.worldline .cred-mgmt .left-box-wrapper {
  height: auto;
  margin-top: 6%;
}
body.worldline .cred-mgmt .submit-btn {
  background: #46beaa;
  color: rgb(0, 0, 0);
}

/*default theme ends*/
