@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;1,6..12,200&family=Nunito:wght@200;300&display=swap");
:root {
  --psa_filter_expand: #0099ff;
  height: 100vh;
}
#root {
  min-height: 100%;
  height: fit-content;
}

div[data-testid="theme-name"] {
  min-height: 90vh;
  height: fit-content;
}
#webpack-dev-server-client-overlay {
  display: none;
}
body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--psa_theme_background) */
  padding-right: 0 !important;
  overflow: auto !important;
}
body.psa.worldline {
  background-color: var(--psa_theme_background);
}
body.worldline {
  background-color: var(--default_bg_color);
}

code {
  font-family: "Nunito", sans-serif;
}
