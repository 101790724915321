.WL-Footer {
  background-color: transparent;
  padding-top: 20px;
  position: relative;
  bottom: 0;
  font-family: Nunito;
  font-size: 14px;
  color: #2e3358a3;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 50px;
}
.WL-Footer[id*="password"] {
  background: #eff1f5;
}
@media screen and (max-width: 1200px) {
  .WL-Footer {
    position: relative;
    top: 60%;
  }
}
