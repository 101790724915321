.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
}
header.MuiPaper-root
  .MuiContainer-root.MuiContainer-maxWidthXl
  .MuiToolbar-root.MuiToolbar-regular
  .MuiBox-root
  .environmentInfo.MuiBox-root {
  margin-left: 17%;
  background-color: #ffc966;
  padding: 2px 10px;
  color: #fff8dc;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

header.MuiPaper-root {
  background-color: #fff;
  margin-bottom: 50px;
  color: black;
  margin-bottom: 0.5%;
  z-index: 2000;
  position: relative;
}

.SuccessMessageGrid {
  top: 55%;
  z-index: 2500;
  position: absolute;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.MuiList-root.MuiList-padding.MuiMenu-list
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters[role="menuitem"]:hover {
  background-color: #fff;
}
.SuccessMessageGrid {
  width: 70%;
}
.SuccessMessageGrid .success-msg {
  flex-grow: initial;
  height: 30px;
  font-family: Nunito;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  background-color: rgb(46, 125, 50);
  color: rgb(255, 255, 255);
  width: fit-content;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 16px;
  align-items: center;
}
.ErrorMessageGrid.app-bar {
  position: absolute;
  margin: 6% 0 0 11%;
  width: 70%;
}
.ErrorMessageGrid.app-bar .global-error-msg {
  margin: 0 auto;
  width: fit-content;
}
header.MuiPaper-root .MuiContainer-root.MuiContainer-maxWidthXl {
  max-width: none;
  min-width: none;
}

.logoutMenu
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper
  ul
  > li:nth-of-type(1) {
  display: inline-block;
}

.MuiMenu-root.logoutMenu.MuiPopover-root.MuiModal-root
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper {
  overflow: hidden;
  width: 200px;
  min-width: 190px;
  height: auto;
}
.MuiMenu-root.logoutMenu.MuiPopover-root.MuiModal-root
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper
  ul
  > li
  > div
  > span {
  color: #414141;
  font-size: 14px;
  font-family: "Nunito";
  margin-left: -1px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper
  .MuiList-root.MuiList-padding.MuiMenu-list
  .MuiBox-root
  span {
  text-transform: none;
}

.MuiMenu-root.logoutMenu.MuiPopover-root.MuiModal-root
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper
  ul
  > li
  > div
  > svg:nth-of-type(1) {
  margin-left: 3px;
}
.MuiStack-root.avatar-wrapper {
  display: flex;
  align-items: center;
}
/* psa theme starts*/
body.psa.worldline .MuiStack-root.avatar-wrapper {
  color: var(--psa_admin-font-color);
}
body.psa.worldline .key-down-arrow {
  font-size: 25px;
  color: #108ee9;
}
body.psa.worldline .csmMenuPortalLink {
  display: flex;
}

/* psa theme ends*/
/* default theme starts*/
body.worldline .MuiStack-root.avatar-wrapper {
  color: #20534a;
}
body.worldline .key-down-arrow {
  font-size: 25px;
  color: #20534a;
}

body.worldline .csmMenuPortalLink {
  display: none;
}
/* psa theme ends*/

.MuiContainer-root.MuiContainer-maxWidthXl .MuiToolbar-root.MuiToolbar-regular .topMenuBar.MuiBox-root {
  margin-right: 20px;
}
