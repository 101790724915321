.roleAssignmentReportContainer
  .role-sub-heading-wrapper
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium {
  top: 0;
}

.roleAssignmentReportContainer .role-sub-heading-wrapper {
  margin-top: 40px;
}
.roleAssignmentReportContainer .role-sub-heading-wrapper h4 {
  font-size: 22px;
  line-height: 1.235;
  font-weight: 700;
}
.report-table-wrapper
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2
  div.MuiBox-root:nth-of-type(1)
  div:nth-of-type(3)
  > div
  > div {
  width: auto;
}
.report-table-wrapper .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
  width: 100%;
}
.report-table-wrapper table.roles_details_pannel thead.MuiTableHead-root th div.Mui-TableHeadCell-Content {
  padding: 8px 20px;
}
.report-table-wrapper div.MuiBox-root {
  background-color: transparent;
}
.report-table-wrapper table thead tr th:nth-of-type(1) div:nth-of-type(1) .Mui-TableHeadCell-Content-Wrapper {
  max-height: 24px;
}
.report-table-wrapper table thead.MuiTableHead-root th div.Mui-TableHeadCell-Content {
  margin-bottom: 0;
  padding: 5px 0 7px 25px;
}
.report-table-wrapper table thead.MuiTableHead-root th:nth-child(1) div.Mui-TableHeadCell-Content {
  padding-left: 15px;
}
.report-table-wrapper hr {
  opacity: 0.7;
}

.report-table-wrapper .single-role,
.report-table-wrapper .multiple-roles {
  margin-left: 25px;
}
.report-table-wrapper table.MuiTable-root thead tr th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft {
  padding: 0;
}
.report-table-wrapper table.MuiTable-root thead tr th:nth-child(1) {
  padding-left: 15px;
}
.report-table-wrapper div.MuiBox-root {
  box-shadow: none;
}
.roles_details_pannel div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  width: 100%;
}
.roles_details_pannel table thead.MuiTableHead-root th div.Mui-TableHeadCell-Content {
  background-color: #fff;
}
.report-table-wrapper .download-option-btns {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  position: absolute;
  z-index: 200;
  min-width: 140px;
  text-align: center;
}
.report-table-wrapper table.MuiTable-root tbody td {
  padding: 0;
}
.report-table-wrapper table.MuiTable-root tbody td:nth-child(1) {
  padding-left: 15px;
}
.report-table-wrapper table.MuiTable-root tbody td:nth-child(2) {
  padding: 10px 0;
}
.report-table-wrapper .global-search-textbox-wrapper {
  margin-left: -3%;
  margin-right: 1%;
}
.report-table-wrapper .roles_details_pannel table.MuiTable-root tbody td {
  padding: 5px 15px;
}
.report-table-wrapper tbody::-webkit-scrollbar-thumb {
  background-color: #dce3f3;
  border-radius: 10px;
  height: 10px;
}
.report-table-wrapper tbody::-webkit-scrollbar {
  width: 6px;
}
#base-report-wrapper .report-btn {
  border-radius: 20px;
}
#base-report-wrapper .MuiContainer-maxWidthLg {
  max-width: 100vw;
  margin-top: 2%;
}
#base-report-wrapper .MuiAlert-colorError.MuiAlert-standardError {
  width: fit-content;
}
#base-report-wrapper .ErrorMessageGrid .global-error-msg {
  width: fit-content;
  height: fit-content;
}
#base-report-wrapper .ErrorMessageGrid .global-error-msg ul {
  text-align: left;
  padding: 0 0 0 10px;
  margin: 0;
}
#base-report-wrapper .report-btn.loading-btn.Mui-disabled {
  color: transparent;
}
#base-report-wrapper .report-error-container {
  display: flex;
  align-items: flex-start;
}
#base-report-wrapper .report-error-container .close-icn {
  padding-left: 15px;
  cursor: pointer;
}
.roles_details_pannel table .Mui-TableHeadCell-Content-Wrapper.MuiBox-root {
  font-weight: bold;
  text-transform: uppercase;
}
.report-table-wrapper .MuiCircularProgress-svg {
  display: none;
}
.roles_details_pannel {
  margin-top: 20px;
}
#base-report-wrapper .report-btn.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
}
.report-table-wrapper .show-hide-toggle {
  width: 100%;
}
.report-table-wrapper .sub-row-wrapper {
  padding: 8px 8px;
}
.report-table-wrapper .padding-lr-10 {
  padding: 0 20px;
}
#base-report-wrapper .heading-bar .report-container button {
  top: 0;
}
#base-report-wrapper .report-container {
  margin-top: 30px;
}
.report-table-wrapper .status-active,
.report-table-wrapper .status-inactive {
  margin-left: 20px;
}
.report-table-wrapper .Mui-TableHeadCell-ResizeHandle-Wrapper {
  position: relative;
  margin-right: 30px;
  padding-left: 0;
  padding-right: 0;
}
.report-table-wrapper .sub-row-key {
  font-weight: bold;
  font-size: 14px;
  color: rgb(65, 65, 65);
  opacity: 0.9;
  margin: 7px 0;
}

/* default theme starts here*/
body.worldline .download-option-btns {
  border: 1px solid #46beaa;
}
body.worldline .roles_details_pannel table .Mui-TableHeadCell-Content-Wrapper.MuiBox-root {
  color: #277777;
}
body.worldline .report-btn {
  background-color: var(--default_btn_color);
  color: rgb(0, 0, 0);
}
body.worldline .download-option-btns button {
  color: var(--default-user-name-link-color);
}
body.worldline .show-hide-toggle {
  color: var(--default-user-name-link-color);
}
body.worldline .ArrowRightSharpIcon,
body.worldline .ArrowDropDownSharpIcon {
  color: var(--default_table_header-bg);
}
/* default theme ends here*/

/* --------------------------------------------------------------------------------- */

/* PSA theme starts here*/
body.worldline.psa .roles_details_pannel table .Mui-TableHeadCell-Content-Wrapper.MuiBox-root {
  color: #1976d2;
}
body.worldline.psa .report-btn {
  background-color: rgb(0, 153, 255);
  color: #fff;
}
body.worldline.psa .download-option-btns {
  border: 1px solid var(--psa_theme_dark);
}
body.worldline.psa .download-option-btns button,
body.psa.worldline .show-hide-toggle {
  color: var(--psa-user-name-link-color);
}
body.worldline.psa .ArrowRightSharpIcon,
body.worldline.psa .ArrowDropDownSharpIcon {
  color: var(--psa-user-name-link-color);
}
/* PSA theme ends here*/
/* Media queries start */
@media screen and (min-width: 480px) {
  .report-table-wrapper .flex_box-space {
    max-width: 98%;
  }
}
