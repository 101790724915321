div[data-testid="access-log-page"] .report-table-wrapper .padding-lr-10 {
  padding: 0 15px;
}
div[data-testid="access-log-page"] .report-table-wrapper .access-log-sub-wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 20px !important;
}
div[data-testid="access-log-page"] .report-table-wrapper .Mui-TableBodyCell-DetailPanel {
  padding: 0;
  background-color: #fff;
}
div[data-testid="access-log-page"] .report-table-wrapper .Mui-TableBodyCell-DetailPanel tbody td {
  padding-right: 20px;
}
div[data-testid="access-log-page"]
  .report-table-wrapper
  table
  thead.MuiTableHead-root
  th:nth-child(1)
  div.Mui-TableHeadCell-Content {
  height: 100%;
}
div[data-testid="access-log-page"] .report-table-wrapper .expand-collapse-details {
  width: 100%;
  text-align: left;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  font-family: "Nunito", sans-serif;
}

/* PSA specific style start */
.psa.worldline div[data-testid="access-log-page"] .report-table-wrapper table svg[data-testid="AddOutlinedIcon"],
.psa.worldline div[data-testid="access-log-page"] .report-table-wrapper table svg[data-testid="RemoveOutlinedIcon"] {
  color: var(--psa_filter_expand);
  opacity: 0.8;
}
.psa.worldline div[data-testid="access-log-page"] .report-table-wrapper .expand-collapse-details {
  color: var(--psa-user-name-link-color);
}
.worldline.psa div[data-testid="access-log-page"] .report-table-wrapper .access-log-sub-wrapper {
  border: 1px solid var(--psa_table_border);
  border-bottom: none;
}
.worldline.psa div[data-testid="access-log-page"] .report-table-wrapper .access-log-sub-wrapper th {
  border-bottom: 1px solid var(--psa_table_border);
}
body.worldline.psa
  div[data-testid="access-log-page"]
  .report-table-wrapper
  .expand-collapse-details.expand-collapse-all-details {
  color: var(--psa-user-name-link-color);
}
div[data-testid="access-log-page"]
  #base-report-wrapper
  .report-table-wrapper
  table.MuiTable-root
  thead
  tr
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft
  span.MuiBadge-root {
  display: block;
  visibility: visible;
}
/* PSA specific style end */
/* Default specific style start */
.worldline .report-table-wrapper .expand-collapse-details {
  color: var(--default-user-name-link-color);
}
body.worldline
  div[data-testid="access-log-page"]
  .report-table-wrapper
  .expand-collapse-details.expand-collapse-all-details {
  color: #fff;
}
.worldline div[data-testid="access-log-page"] .report-table-wrapper table svg[data-testid="AddOutlinedIcon"],
.worldline div[data-testid="access-log-page"] .report-table-wrapper table svg[data-testid="RemoveOutlinedIcon"] {
  color: var(--default_table_header-bg);
  opacity: 0.8;
}
.worldline div[data-testid="access-log-page"] .report-table-wrapper .access-log-sub-wrapper {
  border: 1px solid rgba(224, 224, 224, 1);
  border-bottom: none;
}
.worldline div[data-testid="access-log-page"] .report-table-wrapper .access-log-sub-wrapper th {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
/* Default specific style end */
