.edit-user-paper {
  width: 90%;
  margin: 0 auto;
  padding: 25px 20px;
}
.grid-2 {
  display: flex;
  justify-content: flex-start;
}
hr {
  margin: 2% 0 1% 0;
  opacity: 0.4;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.EditUserWrapper
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium {
  top: 0px;
  margin-left: 0px;
}
body.worldline
  .MuiFormGroup-root.Statusbutton
  .Mui-disabled.PrivateSwitchBase-root
  svg[data-testid="RadioButtonUncheckedIcon"] {
  color: rgba(0, 0, 0, 0.26);
}
.role-description .MuiInputBase-multiline textarea {
  font-size: 13px;
}
.role-description .MuiInputBase-multiline {
  padding: 10px;
}
.role-description .MuiInputBase-multiline textarea::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.role-description .MuiInputBase-multiline textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #55555523;
}
.MuiGrid-root .MuiGrid-item .role-description {
  margin-top: 20px;
}
label.input-label {
  text-transform: uppercase;
  margin-bottom: 15px;
}
#edit-uder-top-wrapper .heading-bar {
  width: 90%;
  margin: 2% 2.5% 1% 2.5%;
}
.error-msg {
  font-size: 13px;
  color: red;
  margin-top: 5px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.EditUserWrapper .MuiGrid-root.MuiGrid-grid-md-3.ErrorMessageGrid {
  width: 80%;
  top: 8%;
  z-index: 2500;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.edit-user-paper
  .MuiGrid-root.MuiGrid-container.first-row-container {
  margin-bottom: 40px;
}

.ErrorMessageGrid .global-error-msg {
  min-width: fit-content;
  height: fit-content;
  font-family: Nunito;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  background: #ff0000b3;
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 16px 15px 16px;
  align-items: center;
}
#edit-uder-top-wrapper .MuiGrid-root.MuiGrid-grid-md-3.ErrorMessageGrid {
  width: 80%;
  top: 8%;
  z-index: 2500;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ErrorMessageGrid .global-error-msg .error-message-items,
.ErrorMessageGrid .global-error-msg .error-message-header {
  text-align: left;
}
.Description-box .MuiInputBase-formControl.MuiInputBase-multiline {
  max-height: 220px;
  margin-top: 10px;
}

.Statusbutton svg {
  width: 20px;
  height: 20px;
}
.Status-box fieldset.MuiFormControl-root {
  padding-left: 30px;
}
.Status-box fieldset.MuiFormControl-root label span.MuiFormControlLabel-label {
  font-size: 15px;
}
.edit-footer-wrapper .WL-Footer {
  position: relative;
  margin-top: 20px;
  top: 0;
}
.first-row-container.MuiGrid-root.MuiGrid-container {
  margin-left: 0;
}
.edit-user-paper .MuiGrid-container .toolbar-btn {
  display: flex;
  max-height: 45px;
}
.edit-user-paper .identity-user-disable .rdl-actions button,
.edit-user-paper .identity-user-disable .rdl-control-container select {
  background-color: #eee;
  opacity: 0.8;
}
.edit-user-paper .identity-user-disable .rdl-actions button span {
  color: rgba(0, 0, 0, 0.38);
}
div.modified-by-info,
div.created-by-info {
  color: #000;
  font-size: 14px;
  margin: 0;
  width: 25%;
  padding: 13px;
  border-radius: 5px;
}
.modified-by-info svg,
.created-by-info svg {
  margin-right: 10px;
  font-size: 16px;
  vertical-align: bottom;
}
#more-info-panel-header .MuiAccordionSummary-content.Mui-expanded {
  margin: 15px 0;
}
.edit-user-paper .more-info-accordian {
  position: static;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}
.modified-by-name,
.created-by-name {
  margin-left: 24px;
}
#more-info-panel-header.Mui-expanded {
  min-height: auto;
}
#more-info-panel-header .MuiAccordionSummary-expandIconWrapper {
  margin-right: 10px;
}
.more-info-container {
  margin-left: 25px;
}
.edit-user-paper .identity-user-disable .rdl-actions button {
  cursor: no-drop;
  pointer-events: none;
}
/*PSA THEME starts*/
body.psa.worldline {
  width: 100%;
}
body.psa.worldline .manage-two-fa,
body.psa.worldline .managae-two-fa-arrow,
body.psa.worldline .MuiFormGroup-root.Statusbutton svg[data-testid="RadioButtonUncheckedIcon"],
body.psa.worldline .MuiFormGroup-root.Statusbutton .Mui-checked {
  color: #0099ff;
}

body.psa.worldline .manage-two-fa.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
/*PSA THEME ends*/
/*default THEME starts*/
body.worldline .manage-two-fa,
body.worldline .managae-two-fa-arrow,
body.worldline .MuiFormGroup-root.Statusbutton svg[data-testid="RadioButtonUncheckedIcon"],
body.worldline .MuiFormGroup-root.Statusbutton .Mui-checked {
  color: #46beaa;
}
body.worldline .manage-two-fa.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}

/*default THEME ends*/
