.MuiToolbar-root.MuiToolbar-regular {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 85px;
}
.Mui-TableHeadCell-Content-Wrapper {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  color: rgb(46, 51, 88);
  font-weight: 500;
  opacity: 0.9;
}
table.MuiTable-root .MuiTableHead-root {
  opacity: 1;
}
table.MuiTable-root thead tr th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft {
  padding: 0 30px 7px 0;
}
table thead .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8px 0;
}
.user-list-table .MuiTableContainer-root {
  min-height: 300px;
}

table thead tr .MuiInput-underline:before,
table thead tr .MuiInput-underline:focus {
  border: none;
}

.flex_box-space.MuiBox-root .MuiTypography-root.MuiTypography-h5 span.header-5-users {
  font-size: 30px;
  font-weight: bold;
}

/* div[data-testid="theme-name"] .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium {
  top: 44px;
  margin-left: 40px;
} */

div[data-testid="theme-name"] .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1 {
  margin-top: 0px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 .paginationClasses .MuiPaginationItem-root {
  min-width: 25px;
  height: 25px;
  border-radius: 8px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 .MuiFormLabel-root.MuiInputLabel-root {
  font-family: "Nunito";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.43;
  color: rgb(46 51 88 / 45%);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2
  .MuiInputBase-root.MuiInput-root.MuiInputBase-colorPrimary {
  padding: 2px 10px;
  border-radius: 7px;
  border: 1px solid rgb(217, 217, 217);
  font-size: 14px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2
  .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input {
  padding-bottom: 2px;
  padding-right: 15px;
}

.user-list-table
  table.MuiTable-root
  thead
  tr
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft:nth-of-type(1) {
  padding-right: 0;
  width: 0;
}
.MuiToolbar-regular .MuiBox-root img {
  height: 47px;
}
table.MuiTable-root thead tr th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft span.MuiBadge-root,
table.MuiTable-root
  thead
  tr
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft
  .Mui-TableHeadCell-Content-Actions,
table button[aria-label^="Filtering by Roles"] {
  display: none;
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.menu_list_item .side-menu-icon:hover {
  background: #8080802b;
  border-radius: 4px;
}

#menu-
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper {
  max-width: fit-content;
  min-height: 100px;
  max-height: 170px;
  min-width: 170px;
  overflow: hidden scroll;
  resize: horizontal;
  width: 170px;
  left: 860px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8::-webkit-scrollbar {
  width: 8px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8::-webkit-resizer {
  cursor: e-resize;
  background-position: right;
  background-size: 12px;
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3hkcJjOaDA_hrIIJko7MDu1zqhvOGmEp-HyjQvrUEB8waum4iUR3dt2JNSe7tmxz6hck&usqp=CAU");
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 ul {
  max-width: none;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 ul li {
  font-size: 13px;
  padding-left: 0;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 ul li span {
  padding-right: 0;
  margin-right: 0;
}
table thead tr svg.MuiSelect-icon[data-testid="ArrowDropDownIcon"] {
  margin-right: 0;
}
table thead th input.MuiInputBase-input.MuiOutlinedInput-input[title="Search"],
table thead th div[role="combobox"] {
  padding: 8px 10px;
}
table thead tr div.MuiSelect-select.MuiSelect-outlined .MuiBox-root {
  display: none;
}
table thead tr div.MuiInputBase-root.MuiOutlinedInput-root {
  max-width: 200px;
}
table thead tr label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  top: -6px;
}
table thead span[aria-label="Clear filter"] {
  display: none;
}
table thead.MuiTableHead-root th div.Mui-TableHeadCell-Content {
  padding: 5px 30px 5px 0;
  margin-bottom: 5px;
}
table thead tr th:nth-of-type(1) div.Mui-TableHeadCell-Content {
  padding-right: 60px;
}
.user-list-table table thead tr th:nth-of-type(1) div.Mui-TableHeadCell-Content div.Mui-TableHeadCell-Content-Wrapper {
  color: transparent;
}
table thead tr th:nth-of-type(1) div:nth-of-type(1) {
  min-width: 20px;
}
table thead tr th:nth-of-type(1) div:nth-of-type(2) {
  visibility: hidden;
  width: 20px;
}

table.MuiTable-root tbody td:nth-of-type(1) {
  padding: 7.5px 16px;
}
table.MuiTable-root tbody td {
  padding: 0 5px;
  font-family: "Nunito", sans-serif;
  color: rgb(46, 51, 88);
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.9;
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded ul[role="listbox"] li {
  text-transform: none;
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded ul li:nth-of-type(1)[data-value="5"] {
  display: block;
}
table thead tr svg[data-testid="FilterAltIcon"] {
  display: none;
}
.show-hide-col button,
.full-screen-toggle button {
  border: 1px solid rgb(207, 207, 207);
  margin: 2px;
  padding: 5px 35px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Nunito", sans-serif;
}
.show-hide-col button,
.full-screen-toggle button {
  padding-top: 0;
  padding-bottom: 0;
}
.show-hide-col button svg,
.full-screen-toggle button svg {
  display: none;
}
.show-hide-col button::before {
  content: "Show/Hide Columns";
  padding: 5px 20px;
}
.show-hide-col {
  display: flex;
  align-items: center;
}
.full-screen-toggle button::before {
  content: "Full Screen";
}
.flex_box-space {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}
/* .flex_box-space.MuiBox-root .MuiTypography-root.MuiTypography-h5 {
  margin-left: 50px;
} */
.flex_box-space.MuiBox-root .MuiTypography-root.MuiTypography-h5 b {
  font-size: 32px;
  line-height: 1.235;
  font-weight: 700;
}
.flex_box-space.MuiBox-root .MuiTypography-root.MuiTypography-h5 span {
  font-size: 22px;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.UserListContainer .flex_box-center .add-user-btn {
  padding: 5px 20px;
  border-radius: 20px;
}
.flex_box-center {
  display: flex;
  align-items: center;
}
svg.selectColumnsIcon-show-eye {
  margin: 0 -16% 0 15%;
  z-index: 1;
  font-size: 16px;
}
svg.selectColumnsIcon-arrow-down {
  margin: 0 0% 0 -16%;
  z-index: 1;
  font-size: 16px;
}
.error_container.list_user .ErrorMessageGrid {
  margin: 0 auto;
}
.MuiTableContainer-root::-webkit-scrollbar {
  height: 8px;
}
.MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: #fff;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 ul li {
  color: var(--psa_theme_dark);
}

.paginationClasses .MuiButtonBase-root.Mui-selected {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.81);
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 div:nth-of-type(3) > div > div > div {
  width: 100%;
  gap: 0;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8::-webkit-scrollbar-thumb {
  background-color: var(--psa_scrollbar-thumb);
}
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: var(--psa_scrollbar-thumb);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 div:nth-of-type(3) > div > div {
  width: 100%;
  justify-content: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 div:nth-of-type(3) > div > div > div > nav {
  display: inline-block;
  margin: 0 auto;
}
/* Start : Show/hide column CSS */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 {
  width: 95%;
  margin: 0 auto;
  box-shadow: none;
}
.MuiMenu-root.MuiPopover-root li.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root[data-testid="logout-menu"] {
  max-height: none;
  text-transform: uppercase;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  background-color: #fff;
}
.MuiMenu-root.MuiPopover-root li.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root {
  max-height: 30px;
  text-transform: uppercase;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  background-color: #fff;
}
.MuiMenu-root.MuiPopover-root
  li.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root
  .MuiTypography-root.MuiFormControlLabel-label {
  font-size: 14px;
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 {
  box-shadow: none;
  margin-top: 4px;
}
body.worldline .MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded span.MuiSwitch-track {
  background-color: grey;
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded ul li:nth-of-type(1),
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded ul hr,
.MuiMenu-root.MuiPopover-root
  .MuiPaper-elevation.MuiPaper-rounded
  ul
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  display: none;
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded ul li[data-testid="logout-menu"] {
  display: block;
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded ul li[data-testid="logout-menu"] {
  transform: translateX(18px);
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded span.MuiSwitch-thumb {
  width: 16px;
  height: 16px;
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded span.MuiSwitch-track {
  height: 12px;
  width: 25px;
}

.MuiTableContainer-root {
  border: 1px solid var(--psa_table_border);
  border-radius: 10px;
}
.MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded ul li svg[data-testid="CheckBoxOutlineBlankIcon"] {
  font-size: 20px;
}
.user-list-table table .roleFilterInput legend,
.user-list-table table .entityFilterInput legend {
  width: 0;
}
.user-list-table table thead tr th:nth-of-type(5) {
  z-index: 1;
}
@media only screen and (max-width: 900px) {
  .flex_box-space.MuiBox-root .MuiTypography-root.MuiTypography-h5 {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  div[data-testid="theme-name"]
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium {
    margin-left: 19px;
  }
}

@media only screen and (max-width: 900px) {
  .UserListContainer .flex_box-center .add-user-btn {
    margin-left: 50px;
  }
}

/* End : Show/hide column CSS */
/*Theme specific styles*/
/* PSA */
body.psa.worldline .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2,
body.psa.worldline .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense,
#userListfooter {
  background-color: var(--psa_theme_background);
}
body.psa.worldline .MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded ul li input {
  color: var(--psa_theme_dark);
}
body.psa.worldline table.MuiTable-root tbody td {
  border-bottom: 1px solid var(--psa_table_border);
}
body.psa.worldline table.MuiTable-root tbody .Mui-TableBodyCell-DetailPanel {
  border-bottom: none;
}
body.psa.worldline .MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 {
  border: 1px solid var(--psa_theme_dark);
}
body.psa.worldline
  .MuiMenu-root.MuiPopover-root
  .MuiPaper-elevation.MuiPaper-rounded
  span.MuiSwitch-switchBase.Mui-checked
  + span.MuiSwitch-track {
  background-color: rgb(25, 118, 210);
}

body.psa.worldline .MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded span.MuiSwitch-thumb {
  color: rgb(25, 118, 210);
}
body.psa.worldline
  .MuiMenu-root.MuiPopover-root
  .MuiPaper-elevation.MuiPaper-rounded
  ul
  li
  svg[data-testid="CheckBoxOutlineBlankIcon"] {
  fill: var(--psa_theme_dark);
}
body.psa.worldline .paginationClasses .MuiButtonBase-root.Mui-selected {
  border: 1px solid var(--psa-pagination-border);
}
body.psa.worldline thead.MuiTableHead-root th div.Mui-TableHeadCell-Content {
  background-color: var(--psa_table_border);
}

.Mui-TableHeadCell-Content-Wrapper {
  color: rgb(46, 51, 88);
}

body.psa.worldline .add-user-btn {
  background-color: rgb(0, 153, 255);
  color: #fff;
}

body.psa.worldline .expand-left-bar-icon {
  filter: opacity(0.5) drop-shadow(0 0 0 blue);
}
body.psa.worldline .add-user-btn,
body.psa.worldline .add-user-btn:hover {
  background-color: #1976d2;
}

body.psa.worldline .Mui-TableHeadCell-Content-Wrapper {
  color: #000;
}

body.worldline.psa
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8
  .MuiMultiSectionDigitalClock-root
  ul
  li,
body.worldline.psa .MuiPickersLayout-root .MuiPickersLayout-actionBar button {
  color: var(--psa_theme_dark);
}
body.worldline.psa
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8
  .MuiMultiSectionDigitalClock-root
  ul
  li.Mui-selected {
  color: #fff;
  background-color: var(--psa_theme_dark);
}
body.worldline.psa .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition button.Mui-selected {
  background-color: var(--psa_theme_dark);
}

/*Ends PSA theme*/
/* starts default theme */
body.worldline .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2,
body.worldline .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense,
#userListfooter {
  background-color: var(--default_bg_color);
}
body.worldline .MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 {
  border: 1px solid #46beaa;
}
body.worldline
  .MuiMenu-root.MuiPopover-root
  .MuiPaper-elevation.MuiPaper-rounded
  ul
  li
  svg[data-testid="CheckBoxOutlineBlankIcon"] {
  fill: #46beaa;
}
body.worldline
  .MuiMenu-root.MuiPopover-root
  .MuiPaper-elevation.MuiPaper-rounded
  span.MuiSwitch-switchBase.Mui-checked
  + span.MuiSwitch-track {
  background-color: var(--default_btn_color);
}
body.worldline .MuiMenu-root.MuiPopover-root .MuiPaper-elevation.MuiPaper-rounded span.MuiSwitch-thumb {
  color: var(--default_btn_color);
}
body.worldline .expand-left-bar-icon {
  filter: opacity(0.5) drop-shadow(0 0 0 green);
}

body.worldline .add-user-btn {
  background-color: var(--default_btn_color);
  color: rgb(0, 0, 0);
}
body.worldline .add-user-btn:hover {
  background-color: var(--default_btn_color);
}
body.worldline .paginationClasses .MuiButtonBase-root.Mui-selected {
  border: 1px solid var(--default-pagination-border);
  border-radius: 3px;
}

body.worldline thead.MuiTableHead-root th div.Mui-TableHeadCell-Content {
  background-color: var(--default_table_header-bg);
  color: #fff;
}
body.worldline .Mui-TableHeadCell-Content-Wrapper {
  color: #fff;
}
body.worldline
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8
  .MuiMultiSectionDigitalClock-root
  ul
  li,
body.worldline .MuiPickersLayout-root .MuiPickersLayout-actionBar button {
  color: var(--default-user-name-link-color);
}
body.worldline
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8
  .MuiMultiSectionDigitalClock-root
  ul
  li.Mui-selected {
  color: #fff;
  background-color: var(--default_btn_color);
}
body.worldline .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition button.Mui-selected {
  background-color: var(--default_btn_color);
}
/*newly added css*/
.left-container button {
  margin-top: 5px;
  margin-bottom: 5px;
}
.right-container {
  width: 98%;
  margin-left: 2%;
}
