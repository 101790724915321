.last-login-report-container {
  max-width: -webkit-fill-available;
}

.last-login-report-container label.MuiInputLabel-shrink {
  display: none;
  height: 0;
}
.last-login-report-container legend {
  width: 0;
}

div[data-testid="user-last-login-page"]
  #base-report-wrapper
  .report-table-wrapper
  .Mui-TableHeadCell-ResizeHandle-Wrapper {
  padding: 0;
  margin: 0;
  visibility: hidden;
}
div[data-testid="user-last-login-page"]
  #base-report-wrapper
  .report-table-wrapper
  table.MuiTable-root
  thead
  tr
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft
  span.MuiBadge-root {
  visibility: visible;
  display: block;
}

div[data-testid="user-last-login-page"] .MuiTableContainer-root {
  overflow: hidden;
}
