.tootlTipTitle {
  border-radius: 5px;
  border: 0.5px solid #ecfffc;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  padding: 1px 2px;
}
.MuiStack-root.roleField {
  color: #2e3358;
  height: 40px;
  display: flex;
  align-items: center;
}
/*default theme*/
body.worldline .tootlTipTitle {
  border: 0.8px solid #46beaa;
}
