.userListFooter {
  margin-top: 50px;
  font-family: Nunito;
  font-size: 14px;
  color: hsla(233, 31%, 26%, 0.639);
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 50px;
}
