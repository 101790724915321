.access-denied-wrapper {
  height: 80vh;
  margin: 2%;
}
.access-denied-wrapper .access-denied-copmonent {
  padding: 20px;
  border: 1px solid #80808069;
  width: fit-content;
  margin: 0 auto;
}
.access-denied-wrapper .access-denied-copmonent h1 {
  color: brown;
}
.access-denied-wrapper .access-denied-copmonent button {
  padding: 5px 20px;
  border-radius: 20px;
  border: none;
}
/* PSA specifc css starts */
body.worldline.psa .access-denied-wrapper .access-denied-copmonent button {
  background-color: #09f;
  color: #fff;
}
/* PSA specifc css ends*/

/* default css starts */
body.worldline .access-denied-wrapper .access-denied-copmonent button {
  background-color: var(--default_btn_color);
  color: #000;
}
/* default css ends */
